<template>
    <div class="max-w-[1232px] px-4 mx-auto py-14 md:py-6">
        <div class="flex relative gap-x-8">
            <div class="top-[70px] w-[190px] sticky  left-0 h-full hidden md:flex">
                <aside class="w-full">
                    <ul class="space-y-1">
                        <li @click="filterItems('All')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'All' || searchKeyword.length }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'All' || searchKeyword.length ? '#5D97F7' : '#677788'">
                                            <path d="M9.16667 2.75H3.66667C3.42355 2.75 3.19039 2.84658 3.01849 3.01849C2.84658 3.19039 2.75 3.42355 2.75 3.66667V9.16667C2.75 9.40978 2.84658 9.64294 3.01849 9.81485C3.19039 9.98676 3.42355 10.0833 3.66667 10.0833H9.16667C9.40978 10.0833 9.64294 9.98676 9.81485 9.81485C9.98676 9.64294 10.0833 9.40978 10.0833 9.16667V3.66667C10.0833 3.42355 9.98676 3.19039 9.81485 3.01849C9.64294 2.84658 9.40978 2.75 9.16667 2.75ZM8.25 8.25H4.58333V4.58333H8.25V8.25ZM12.8333 10.0833H18.3333C18.5764 10.0833 18.8096 9.98676 18.9815 9.81485C19.1534 9.64294 19.25 9.40978 19.25 9.16667V3.66667C19.25 3.42355 19.1534 3.19039 18.9815 3.01849C18.8096 2.84658 18.5764 2.75 18.3333 2.75H12.8333C12.5902 2.75 12.3571 2.84658 12.1852 3.01849C12.0132 3.19039 11.9167 3.42355 11.9167 3.66667V9.16667C11.9167 9.40978 12.0132 9.64294 12.1852 9.81485C12.3571 9.98676 12.5902 10.0833 12.8333 10.0833ZM13.75 4.58333H17.4167V8.25H13.75V4.58333ZM2.75 18.3333C2.75 18.5764 2.84658 18.8096 3.01849 18.9815C3.19039 19.1534 3.42355 19.25 3.66667 19.25H9.16667C9.40978 19.25 9.64294 19.1534 9.81485 18.9815C9.98676 18.8096 10.0833 18.5764 10.0833 18.3333V12.8333C10.0833 12.5902 9.98676 12.3571 9.81485 12.1852C9.64294 12.0132 9.40978 11.9167 9.16667 11.9167H3.66667C3.42355 11.9167 3.19039 12.0132 3.01849 12.1852C2.84658 12.3571 2.75 12.5902 2.75 12.8333V18.3333ZM4.58333 13.75H8.25V17.4167H4.58333V13.75ZM11.9167 18.3333C11.9167 18.5764 12.0132 18.8096 12.1852 18.9815C12.3571 19.1534 12.5902 19.25 12.8333 19.25H18.3333C18.5764 19.25 18.8096 19.1534 18.9815 18.9815C19.1534 18.8096 19.25 18.5764 19.25 18.3333V12.8333C19.25 12.5902 19.1534 12.3571 18.9815 12.1852C18.8096 12.0132 18.5764 11.9167 18.3333 11.9167H12.8333C12.5902 11.9167 12.3571 12.0132 12.1852 12.1852C12.0132 12.3571 11.9167 12.5902 11.9167 12.8333V18.3333ZM13.75 13.75H17.4167V17.4167H13.75V13.75Z" :fill="selectedCategory === 'All' || searchKeyword.length ? '#5D97F7' : '#677788'"/>
                                        </svg>
                                    </div>
                                    <span :class="[selectedCategory === 'All' || searchKeyword.length ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">All</span>
                                </div>
                            </div>
                        </li>
                        <li @click="filterItems('Education Tools')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'Education Tools' && !searchKeyword.length  }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'Education Tools'  && !searchKeyword.length ? '#5D97F7' : '#677788'">
                                            <g clip-path="url(#clip0_16513_89854)">
                                                <path d="M1.83331 3.66025C1.83499 3.41951 1.9313 3.1891 2.10145 3.01878C2.27159 2.84847 2.50191 2.75192 2.74265 2.75H19.2573C19.7596 2.75 20.1666 3.15792 20.1666 3.66025V18.3398C20.165 18.5805 20.0687 18.8109 19.8985 18.9812C19.7284 19.1515 19.498 19.2481 19.2573 19.25H2.74265C2.50139 19.2498 2.2701 19.1537 2.09959 18.9831C1.92909 18.8124 1.83331 18.581 1.83331 18.3398V3.66025ZM3.66665 4.58333V17.4167H18.3333V4.58333H3.66665ZM5.49998 6.41667H11V11.9167H5.49998V6.41667ZM7.33331 8.25V10.0833H9.16665V8.25H7.33331ZM5.49998 13.75H16.5V15.5833H5.49998V13.75ZM12.8333 6.41667H16.5V8.25H12.8333V6.41667ZM12.8333 10.0833H16.5V11.9167H12.8333V10.0833Z" :fill="selectedCategory === 'Education Tools'  && !searchKeyword.length ? '#5D97F7' : '#677788'"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16513_89854">
                                                    <rect width="22" height="22" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>
                                    <span :class="[selectedCategory === 'Education Tools' && !searchKeyword.length ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">Education Tools</span>
                                </div>
                            </div>
                        </li>
                        <li @click="filterItems('SEO & Writing')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'SEO & Writing' && !searchKeyword.length }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'SEO & Writing' && !searchKeyword.length ? '#5D97F7' : '#677788'">
                                            <path d="M18.3333 2.75H3.66665C2.65556 2.75 1.83331 3.57225 1.83331 4.58333V17.4167C1.83331 18.4278 2.65556 19.25 3.66665 19.25H18.3333C19.3444 19.25 20.1666 18.4278 20.1666 17.4167V4.58333C20.1666 3.57225 19.3444 2.75 18.3333 2.75ZM18.3333 4.58333L18.3342 8.25H3.66665V4.58333H18.3333ZM3.66665 17.4167V10.0833H18.3342L18.3351 17.4167H3.66665Z" :fill="selectedCategory === 'SEO & Writing' ? '#5D97F7' : '#677788'"/>
                                            <path d="M12.8333 5.5H14.6666V7.33333H12.8333V5.5ZM15.5833 5.5H17.4166V7.33333H15.5833V5.5Z" :fill="selectedCategory === 'SEO & Writing' && !searchKeyword.length ? '#5D97F7' : '#677788'"/>
                                        </svg>
                                    </div>
                                    <span :class="[selectedCategory === 'SEO & Writing' ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">SEO &amp; Writing</span>
                                </div>
                            </div>
                        </li>
                        <li @click="filterItems('Ads & Marketing')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'Ads & Marketing' }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'Ads & Marketing' ? '#5D97F7' : '#677788'">
                                            <path d="M18.3333 3.6665H3.66665C2.65556 3.6665 1.83331 4.48875 1.83331 5.49984V16.4998C1.83331 17.5109 2.65556 18.3332 3.66665 18.3332H18.3333C19.3444 18.3332 20.1666 17.5109 20.1666 16.4998V5.49984C20.1666 4.48875 19.3444 3.6665 18.3333 3.6665ZM18.3333 5.49984V5.96825L11 11.6727L3.66665 5.96917V5.49984H18.3333ZM3.66665 16.4998V8.29017L10.4371 13.5564C10.5977 13.6825 10.7959 13.751 11 13.751C11.2041 13.751 11.4023 13.6825 11.5628 13.5564L18.3333 8.29017L18.3351 16.4998H3.66665Z" :fill="selectedCategory === 'Ads & Marketing' ? '#5D97F7' : '#677788'"/>
                                        </svg>
                                    </div>
                                    <span :class="[selectedCategory === 'Ads & Marketing' ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">Ads & Marketing</span>
                                </div>
                            </div>
                        </li>
                        <li @click="filterItems('Office Tools')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'Office Tools' }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'Office Tools' ? '#5D97F7' : '#677788'">
                                            <path d="M18.3333 2.75H3.66665C2.65556 2.75 1.83331 3.57225 1.83331 4.58333V13.75C1.83331 14.7611 2.65556 15.5833 3.66665 15.5833H10.0833V18.3333H7.33331V20.1667H14.6666V18.3333H11.9166V15.5833H18.3333C19.3444 15.5833 20.1666 14.7611 20.1666 13.75V4.58333C20.1666 3.57225 19.3444 2.75 18.3333 2.75ZM3.66665 13.75V4.58333H18.3333L18.3342 13.75H3.66665Z" :fill="selectedCategory === 'Office Tools' ? '#5D97F7' : '#677788'"/>
                                            <path d="M9.16669 11.9165L13.75 9.1665L9.16669 6.4165V11.9165Z" :fill="selectedCategory === 'Office Tools' ? '#5D97F7' : '#677788'"/>
                                        </svg>

                                    </div>
                                    <span :class="[selectedCategory === 'Office Tools' ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">Office Tools</span>
                                </div>
                            </div>
                        </li>
                        <li @click="filterItems('Music')" :class="{ 'bg-[#F1F6FE] rounded-lg': selectedCategory === 'Music' }">
                            <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:bg-[#F1F6FE]">
                                <div class="text-display gap-4 flex items-center space-x-2 w-full w-fit p-2 rounded-lg hover:cursor-pointer hover:text-primary hover:bg-[#F1F6FE]">
                                    <div class="w-[18px] h-[18px] relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="absolute left-[18px] top-0" viewBox="0 0 22 22" :fill="selectedCategory === 'Music' ? '#5D97F7' : '#677788'">
                                            <path d="M19.25 3.66675H1.83331V5.50008H3.94165L6.94831 13.7501C7.13743 14.285 7.48738 14.7483 7.95017 15.0766C8.41296 15.4048 8.96595 15.5818 9.53331 15.5834H17.4166V13.7501H9.53331C9.34493 13.75 9.16114 13.6919 9.00696 13.5836C8.85278 13.4754 8.7357 13.3222 8.67165 13.1451L8.24998 11.9167H16.7566C17.1545 11.9162 17.5414 11.7862 17.8589 11.5464C18.1764 11.3066 18.4072 10.9701 18.5166 10.5876L20.1666 4.83091C20.2039 4.71054 20.216 4.58378 20.202 4.45854C20.1881 4.3333 20.1485 4.21228 20.0857 4.10304C20.0229 3.99379 19.9383 3.89866 19.8371 3.8236C19.7358 3.74853 19.6203 3.69513 19.4975 3.66675C19.4155 3.6534 19.3319 3.6534 19.25 3.66675ZM16.7291 10.0834H7.56248L5.89415 5.50008H18.0308L16.7291 10.0834Z" :fill="selectedCategory === 'Music' ? '#5D97F7' : '#677788'"/>
                                            <path d="M9.625 19.25C10.3844 19.25 11 18.6344 11 17.875C11 17.1156 10.3844 16.5 9.625 16.5C8.86561 16.5 8.25 17.1156 8.25 17.875C8.25 18.6344 8.86561 19.25 9.625 19.25Z" :fill="selectedCategory === 'Music' ? '#5D97F7' : '#677788'"/>
                                            <path d="M15.125 19.25C15.8844 19.25 16.5 18.6344 16.5 17.875C16.5 17.1156 15.8844 16.5 15.125 16.5C14.3656 16.5 13.75 17.1156 13.75 17.875C13.75 18.6344 14.3656 19.25 15.125 19.25Z" :fill="selectedCategory === 'Music' ? '#5D97F7' : '#677788'"/>
                                        </svg>
                                    </div>
                                    <span :class="[selectedCategory === 'Music' ? 'text-[15px] font-bold text-blue-500' : '', 'text-sm font-semibold']">Lyrics</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </aside>
            </div>
            <div>
                <div class="flex-1 max-w-[970px]">
                    <ul class="space-y-14 md:space-y-6 w-[900px]">
                        <li>
                            <h2 v-if="!searchKeyword.length"  class="text-2xl font-bold md:text-xl">{{ selectedCategory }}</h2>
                            <ul class="grid grid-cols-3 gap-4 mt-5 lg:grid-cols-2 md:grid-cols-1 md:mt-3">
                                <li v-for="(item, index) in searchKeywordData" :key="index" class="border border-[#A563D8] rounded-lg group transition-all hover:shadow-lg">
                                    <a class="p-4 w-full h-full flex gap-2" :href="item.route">
                                        <i :class="item.iconClasses"></i>
                                        <div class="font-semibold group-hover:text-primary flex items-center space-x-2">
                                            {{ item.title }}
                                        </div>
                                    </a>
                                </li>
                            </ul>

                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            items: [
                {
                    title: 'Essay Writer',
                    route: '/ai-writer/ai-essay-writer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/essay-writer.webp)] inline-flex'
                },
                {
                    title: 'Thesis Statement Generator',
                    route: '/ai-writer/thesis-statement-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/thesis-statement-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Book Title Generator',
                    route: '/ai-writer/book-title-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-book-title-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Poem Generator',
                    route: '/ai-writer/poem-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-poem-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Story Generator',
                    route: '/ai-writer/ai-story-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-story-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Paper Writer',
                    route: '/ai-writer/ai-paper-writer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-paper-writer.webp)] inline-flex'
                },
                {
                    title: 'AI Summarizer',
                    route: '/ai-writer/text-summarizer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-summarizer.webp)] inline-flex'
                },
                {
                    title: 'AI Punctuation Checker',
                    route: '/ai-writer/punctuation-checker',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/punctuation-checker.webp)] inline-flex'
                },


                {
                    title: 'Meta Description Generator',
                    route: '/ai-writer/meta-description-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/meta-description-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Blog Writer',
                    route: '/ai-writer/ai-blog-writer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-blog-writer.webp)] inline-flex'
                },
                {
                    title: 'AI FAQ Generator',
                    route: '/ai-writer/ai-faq-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-faq-generator.webp)] inline-flex'
                },
                {
                    title: 'Ai Copy Writer',
                    route: '/ai-writer/ai-copywriter',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-copy-writer.webp)] inline-flex'
                },
                {
                    title: 'Outline Generator',
                    route: '/ai-writer/outline-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/outline-generator.webp)] inline-flex'
                },


                {
                    title: 'AI Email Writer',
                    route: '/ai-writer/email-writer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-email-writer.webp)] inline-flex'
                },
                {
                    title: 'Product Description Generator',
                    route: '/ai-writer/product-description-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/product-description-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Review Generator',
                    route: '/ai-writer/review-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-review-generator.webp)] inline-flex'
                },
                {
                    title: 'Slogan Generator',
                    route: '/ai-writer/slogan-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/slogan-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Script Generator',
                    route: '/ai-writer/ai-script-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-script-generator.webp)] inline-flex'
                },
                {
                    title: 'Introduction Generator',
                    route: '/ai-writer/introduction-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/introduction-generator.webp)] inline-flex'
                },


                {
                    title: 'Cover Letter Writer',
                    route: '/ai-writer/cover-letter-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/cover-letter-generator.webp)] inline-flex'
                },
                {
                    title: 'Letter Writer',
                    route: '/ai-writer/ai-letter-writer',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/letter-writer.webp)] inline-flex'
                },
                {
                    title: 'LinkedIn Summary Generator',
                    route: '/ai-writer/linkedin-summary',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/linkedin-summary-icon.webp)] inline-flex'
                },
                {
                    title: 'Ai Rhyme Generator',
                    route: '/ai-writer/rhyme-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-rhyme-generator.webp)] inline-flex'
                },



                {
                    title: 'AI Paragraph Generator',
                    route: '/ai-writer/paragraph-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-paragraph-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Text Generator',
                    route: '/ai-writer/ai-text-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-text-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Assistant',
                    route: '/ai-writer/writing-assistant',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-writer-assistant.webp)] inline-flex'
                },
                {
                    title: 'Quora Answer Generator',
                    route: '/ai-writer/quora-answer-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/quora-Answer.webp)] inline-flex'
                },
                {
                    title: 'Story Character Generator',
                    route: '/ai-writer/story-character-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/story-character-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Lyrics Generator',
                    route: '/ai-writer/lyrics-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/lyrics-generator.webp)] inline-flex'
                },
                {
                    title: 'Ai Word Generator',
                    route: '/ai-writer/word-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/word-generator.webp)] inline-flex'
                },
                {
                    title: 'Story Name Generator',
                    route: '/ai-writer/story-name-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/store-name-generator.webp)] inline-flex'
                },
                {
                    title: 'Sentence Counter',
                    route: '/ai-writer/sentence-counter',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/sentence-counter.webp)] inline-flex'
                },
                {
                    title: 'ChatGPT Alternative',
                    route: '/ai-writer/chatgpt-alternative',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/chat-gpt-alternative.webp)] inline-flex'
                },
                {
                    title: 'AI Comic Maker',
                    route: '/ai-writer/comic-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-comic-generator.webp)] inline-flex'
                },
                {
                    title: 'Love Letter Generator',
                    route: '/ai-writer/love-letter-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/love-letter-generator.webp)] inline-flex'
                },
                {
                    title: 'Hook Sentence Generator',
                    route: '/ai-writer/hook-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/hook-sentence.webp)] inline-flex'
                },
                {
                    title: 'AI Character Generator',
                    route: '/ai-writer/ai-character-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-copy-writer.webp)] inline-flex'
                },
                {
                    title: 'Haiku Generator',
                    route: '/ai-writer/haiku-poem-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/haiku-generator.webp)] inline-flex'
                },
                {
                    title: 'Plot Generator',
                    route: '/ai-writer/plot-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/plot-generator.webp)] inline-flex'
                },
                {
                    title: 'Topic Sentence Generator',
                    route: '/ai-writer/topic-sentence-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/topic-sentence.webp)] inline-flex'
                },
                {
                    title: 'AI Backstory Generator',
                    route: '/ai-writer/backstory-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/backstory-generator.webp)] inline-flex'
                },
                {
                    title: '4chan Greentext Story Generator',
                    route: '/ai-writer/4chan-greentext',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/4chan-greentext.webp)] inline-flex'
                },

                {
                    title: 'Writing Prompt Generator',
                    route: '/ai-writer/writing-prompt-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-prompt-generator.webp)] inline-flex'
                },
                {
                    title: 'AI Quote Generator',
                    route: '/ai-writer/quote-generator',
                    iconClasses: 'w-6 h-6 bg-contain bg-center bg-[url(/images/icons/ai-quote-generator.webp)] inline-flex"'
                },
            ],

            filteredItems: [],
            selectedCategory: 'All',
        };
    },
    props: ['searchKeyword'],

    /*by default show All*/
    created() {
        this.filteredItems = this.items;
    },
    computed: {
        searchKeywordData() {
            return this.filteredItems.filter(item => {
                return item.title.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1;
            });
        },
    },
    methods: {
        filterItems(category) {
            this.selectedCategory = category;
            this.filteredItems = this.items;
            if (!this.searchKeyword.length){
                if (category === 'All') {
                    this.filteredItems = this.items;
                } else if (category === 'Education Tools') {
                    this.filteredItems = this.items.filter(item => item.title === 'Essay Writer' || item.title === 'Thesis Statement Generator' || item.title === 'AI Book Title Generator' || item.title === 'AI Poem Generator' || item.title === 'AI Story Generator' || item.title === 'AI Paper Writer' || item.title === 'AI Summarizer' || item.title === 'AI Punctuation Checker' || item.title === 'AI Comic Maker' || item.title === 'Sentence Counter' || item.title === 'Topic Sentence Generator' || item.title === 'Ai Word Generator' || item.title === 'AI Character Generator' || item.title === 'Story Character Generator' || item.title === 'Story Name Generator' || item.title === 'Love Letter Generator');
                } else if (category === 'SEO & Writing') {
                    this.filteredItems = this.items.filter(item => item.title === 'Meta Description Generator' || item.title === 'AI Blog Writer' || item.title === 'AI FAQ Generator' || item.title === 'Ai Copy Writer' || item.title === 'Outline Generator' || item.title === 'AI Paragraph Generator' || item.title === 'Plot Generator' || item.title === 'AI Backstory Generator' || item.title === 'Writing Prompt Generator' || item.title === 'Quora Answer Generator' || item.title === 'AI Quote Generator' || item.title === 'Hook Sentence Generator' || item.title === 'ChatGPT Alternative' || item.title === 'AI Text Generator' || item.title === 'AI Assistant' || item.title === '4chan Greentext Story Generator');
                }else if (category === 'Ads & Marketing') {
                    this.filteredItems = this.items.filter(item => item.title === 'AI Email Writer' || item.title === 'Product Description Generator' || item.title === 'AI Review Generator' || item.title === 'Slogan Generator' || item.title === 'AI Script Generator' || item.title === 'Introduction Generator');
                }else if (category === 'Office Tools') {
                    this.filteredItems = this.items.filter(item => item.title === 'Cover Letter Writer' || item.title === 'Letter Writer' || item.title === 'LinkedIn Summary Generator' || item.title === 'AI Email Writer');
                }else if (category === 'Music') {
                    this.filteredItems = this.items.filter(item => item.title === 'Ai Rhyme Generator' || item.title === 'Haiku Generator' || item.title === 'AI Lyrics Generator');
                }
            }
        },
    }
};
</script>
